import React from "react";

const Footer = () => {
  return (
    <div
      className="footer text-center p-3  "
      style={{
        backgroundColor: "#1e1e1e",
      }}
    >
      <h3>
        Made with{" "}
        <span role="img" aria-label="heart">
          ❤️
        </span>{" "}
        by Mayank Sahu
      </h3>
    </div>
  );
};

export default Footer;
